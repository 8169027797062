import axios from './request'
export function CSQuestion(body){
  return axios({
      url: "/PartOrder/CSQuestion",
      headers:{'Content-Type': 'application/json'},
      method: "get",
      withCredentials:true,   
      params: body
  })
}
export function SaveAsync(body){
  return axios({
      url: "/PartOrder/SaveAsync",
      headers:{'Content-Type': 'application/json'},
      method: "post",
      withCredentials:true,   
      data: body
  })
}
export function getCarInfo(body){
  return axios({
      url: "/CarBrand/GetCarInfo",
      method: "get",
      params: body
  })
}
export function partSelect(body){
  return axios({
      url: "/PartOrder/PartSelect",
      method: "get",
      params: body
  })
}
export function disSelect(body){
  return axios({
      url: "/PartOrder/DistributorSelect",
      method: "get",
      params: body
  })
}
