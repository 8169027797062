<template>
  <van-form validate-first @failed="onFailed">
  <van-field
    label="车牌号"
    v-model="subForm.licensePlate"
    name="pattern"
    placeholder="请输入车牌号"
    :rules="[{required:true,message:'请输入正确内容'}]"
  />
  <van-field
    v-model="fieldValue"
    is-link
    readonly
    label="品牌车型"
    placeholder="请选择品牌车型"
    @click="show = true"
  />
<van-field
  v-model="partValue"
  is-link
  readonly
  label="发动机型号"
  placeholder="请选择发动机型号"
  @click="partShow = true"
/>
<van-field
  v-model="disValue"
  is-link
  readonly
  label="渠道商"
  placeholder="请选择渠道商"
  @click="disShow = true"
/>
<van-popup v-model="disShow" round position="bottom">
  <van-cascader
    v-model="disValue"
    title="请选择渠道商"
    :options="disOptions"
    @close="disShow = false"
    @finish="onDisFinish"
  />
</van-popup>
<van-popup v-model="partShow" round position="bottom">
  <van-cascader
    v-model="partValue"
    title="请选择发动机型号"
    :options="partOptions"
    @close="partShow = false"
    @finish="onPartFinish"
  />
</van-popup>
<van-popup v-model="show" round position="bottom">
  <van-field
    label="品牌名称"
    v-model="likeName"
    name="pattern"
    placeholder="请输入品牌"
    @input="onLikeName"
  />
  <van-cascader
    v-model="cascaderValue"
    title="请选择品牌车型"
    :options="options"
    @close="show = false"
    @change="onChange"
    @finish="onFinish"
  />
</van-popup>
  <div style="margin: 16px;">
    <van-button round block type="info" @click="next">下一步</van-button>
  </div>
</van-form>
</template>

<script>
import {Form,Field,Button,Popup,Cascader} from 'vant'
import {getCarInfo,partSelect,disSelect} from '@/api/partOrder.js'
export default {
  components:{
    [Form.name]:Form,
    [Field.name]:Field,
    [Button.name]:Button,
    [Popup.name]:Popup,
    [Cascader.name]:Cascader,
  },
  data() {
    return {
      subForm:{
        licensePlate:'',
        brandId:'',
        modelId:'',
        partId:'',
        distributorId:'',
        questionJson:''
      },
      likeName:'',
      partShow:false,
      partValue:'',
      pattern: /\d{6}/,
      show: false,
      fieldValue: '',
      cascaderValue: '',
      options: [],
      oldOptons:[],
      partOptions:[],
      disValue:'',
      disShow:false,
      disOptions:[]
    };
  },
  created(){
    getCarInfo().then(res=>{
      this.options = res.map(a=>{return {text:a.brandName,value:a.id,children:[],type:-1} })
      this.oldOptons = this.options;
    })
  },
  methods: {
    next(){
      if(!this.subForm.licensePlate){
        this.$toast.fail('请输入车牌号')
        return
      }
      if(!this.subForm.modelId){
        this.$toast.fail('请选择车型')
        return
      }
      window.sessionStorage.setItem('scrapPecesBody',JSON.stringify(this.subForm))
      this.$router.push({path:'/scrapPieces/question'})
    },
    onLikeName(e){
      console.log(e)
      this.options =  this.oldOptons.filter(a=>a.text.indexOf(e)>-1)
    },
    onPartFinish({ selectedOptions }) {
      this.partShow = false;
      this.partValue = selectedOptions.map((option) => option.text).join('/');
      disSelect({partId:selectedOptions[0].value})
      .then(res=>{
        this.disOptions = res.map(a=>{return {text:a.name,value:a.id} })
      })
      this.subForm.partId = selectedOptions[0].value
    },
    onChange(e) {
      var value = e.value
      var o = e.selectedOptions[0];
      if(e.tabIndex == 0){
          getCarInfo({type:e.tabIndex,id:value}).then(res=>{
            o.children = res.map(a=>{return {text:a.seriesName,value:a.id,children:[]} })
          })
      }else if(e.tabIndex == 1){
          getCarInfo({type:e.tabIndex,id:value}).then(res=>{
            var t = o.children.filter(a=>a.value == value)
            t[0].children = res.map(a=>{return {text:a.name,value:a.id} })
          })
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.subForm.brandId = selectedOptions[0].value
      this.subForm.modelId = selectedOptions[2].value
      partSelect({modelId:this.subForm.modelId})
      .then(res=>{
        this.partOptions = res.map(a=>{return {text:a.name,value:a.id} })
      })
    },
    onDisFinish({ selectedOptions }){
      this.disShow = false;
      this.disValue = selectedOptions.map((option) => option.text).join('/');
      this.subForm.distributorId = selectedOptions[0].value
    },
    // 异步校验函数返回 Promise
    asyncValidator(val) {
      return new Promise((resolve) => {
        this.$toast.loading('验证中...');
        setTimeout(() => {
          this.$toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  },
};
</script>

<style>

</style>